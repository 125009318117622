<i18n locale="th" lang="yaml" >
page.title : "{name} :: Ticket ใหม่"
page.description : "เปิด Ticket ใหม่ เพื่อสอบถาม หรือ ปรึกษาการเคลมสำหรับ Drone ตัวนี้"

ticket_create.confirm.title : "ยืนยันการเปิด Ticket"
ticket_create.confirm.message : "คุณต้องการยืนยันการเปิด Ticket ตัวนี้ ?"
ticket_create.success : "เปิด Ticket เรียบร้อยด้วย เลข Ticket {ticket}"
ticket_create.submit.label : "เปิด Ticket"
</i18n>

<template>
	<div id="create_ticket_page" class="page">
		<my-page-header
			:title="$t('page.title',{ name: drone.serialNo })"
			:description="$t('page.description')" />
		<a-card :bordered="false">
			<TicketForm
				ref="formRef"
				:loading="loading"
				mode="create-with-drone"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button id="create_ticket_back_to_create_ticket_search_button"
						:disabled="formLoading"
						icon="left"
						size="large"
						:style="{ 'margin-right': '4px' }"
						@click="goBack">
						{{$t('common.back')}}
					</a-button>
					<a-button id="create_ticket_open_ticket_button"
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('ticket_create.submit.label')}}
					</a-button>
				</template>
			</TicketForm>
		</a-card>
	</div>
</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import TicketForm from "@components/ticket/TicketForm.vue"
import {emptyDrone} from "@utils/objectUtil"

export default {
	components : {
		TicketForm,
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name: this.drone.serialNo})
		}
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		}
	} ,
	data() {
		return {
			loading : false,
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		}
	} ,
	watch : {
		droneData : {
			handler() {
				this.preProcess()
			} ,
			deep: true
		}
	} ,
	mounted(){
		this.preProcess()
	} ,
	methods : {
		preProcess() {
			if (!this.$authorize('create','service')) {
				this.$pageError({name : 'error_forbidden'})
				return
			}
			this.$nextTick(()=>{
				this.$refs.formRef.updateDroneData(this.droneData)
			})
		} ,
		goBack() {
			if (this.$route.query.from_create) {
				return this.$open({name : "ticket/create"})
			} else {
				return this.$open({name : 'drone/view',params : {serial_no : this.drone.serialNo}})
			}
		} ,
		handleSubmit(formData) {
			formData.ticket.droneServiceDroneInstanceId = this.drone.id

			this.$confirm({
				title : this.$t('ticket_create.confirm.title') ,
				content : this.$t('ticket_create.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true

					axios.post('/api/tickets/create',formData).then((response)=>{
						const ticket = response.data.data.ticket;

						this.$message.success(this.$t('ticket_create.success',{"ticket" : ticket.ticket_no}))
						this.$router.replace({name:'ticket/view',params: {id : ticket.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			});
		} ,
	}
}
</script>
